import React from 'react'
import '../UserComponent/UserComponent.scss'
import { Link } from 'react-router-dom'

export default function UserComponent({user}) {
  return (
    <Link to={`/users/${user.id}`}>
      <div className="User-component">
        <div className="component-body">{user.username}</div>
        <div className="component-body" id='email'>{user.email}</div>
        <div className="component-body">Male</div>
        <div className="component-body">24</div>
        <div className="component-body">Online</div>
      </div>
    </Link>
  )
}
