import Navbar from '../../../Components/Navbar/Navbar'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import '../Editprofile/Editprofile.scss'

export default function Profile() {
    return (
        <div className="Profile">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="Profile-container">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="user-profile-info">
                    <div className="left">
                        <div className="title">Artist Info</div>
                        <div className="image-holder">
                            <img src="" alt="" />
                        </div>
                        <div className="user-section-one">
                            <div className="user-item">
                                <input type="text" placeholder='Your username goes here...' />
                            </div>
                            <div className="user-item">
                                <input type="text" placeholder='Your Fullname goes here...' />
                            </div>
                            <div className="user-item">
                                <textarea type="text" placeholder='Your bio goes here...' />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="actions">
                            <div className="save-details">Save</div>
                        </div>
                        <div className="items">
                        <div className="user-item">
                            <input type="text" placeholder='Your Address goes here' />
                        </div>
                        <div className="user-item">
                            <input type="text" placeholder='Your Phone number goes here' />
                        </div>
                        <div className="user-item">
                            <input type="text" placeholder='Your Country goes here' />
                        </div>
                        <div className="user-item">
                            <input type="text" placeholder='Your Spotify goes here' />
                        </div>
                        <div className="user-item">
                            <input type="text" placeholder='Your Sound Cloud goes here' />
                        </div>
                        <div className="user-item">
                            <input type="text" placeholder='Your YouTube goes here' />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}