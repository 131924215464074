import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import New from './Pages/new/New';
import Single from './Pages/single/Single';
import Login from './Pages/login/Login';
import List from './Pages/list/List'
import Home from './Pages/home/Home'
import Music from './Pages/Music/Music'
import Singlemusic from './Pages/Music/Singlemusic/Singlemusic'
import AddNew from './Pages/Music/AddNew/AddNew'
import { userInputs, productInputs } from './formSource'
import Notifications from './Pages/Notifications/Notifications';
import Profile from './Pages/Profile/Profile';
import Editprofile from './Pages/Profile/Editprofile/Editprofile';
import { Context } from './Context/authContext';
import RegisterUsers from './Pages/RegisterUsers/RegisterUsers';
import Distribute from './Pages/Distribute/Distribute'
import { io } from 'socket.io-client';
import SingleDistribution from './Pages/Distribute/SingleDistribution/SingleDistribution';
import { Stats } from './Pages/Stats/Stats';
import { Settings } from './Pages/Settings/Settings';




const Portal = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

function App() {

  const { user } = useContext(Context)

  const socket = useRef()  

  return (
    <Portal>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={user ? <Home /> : <Navigate to='/login' />} />
            <Route path='/login' element={user ? <Home /> : <Login />} />
            <Route exact path='users'>
              <Route index element={<List />} />
              <Route path=':userId' element={<Single />} />
              <Route path='register-user' element={<RegisterUsers />} />
            </Route>
            <Route exact path='music'>
              <Route index element={<Music />} />
              <Route path=':trackId' element={<Singlemusic />} />
              <Route path='new' element={<AddNew />} title="Add New Product" />
            </Route>
            <Route path='/messages' element={<Notifications />} />
            <Route exact path='profile/:userId'>
              <Route index element={<Profile />} />
              <Route path='editprofile' element={<Editprofile />} />
            </Route>
            <Route exact path='distribute'>
              <Route index element={<Distribute />} />
              <Route path=':trackId' element={<SingleDistribution />} />
            </Route>
            <Route exact path='stats'>
              <Route index element={<Stats />} />
            </Route>
            <Route path='/settings' element={<Settings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Portal>
  );
}

export default App;
