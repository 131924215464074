import '../Music/Music.scss'
import { Link } from 'react-router-dom'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import Musicbubble from '../../Components/Musicbubble/Musicbubble'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import { Context } from '../../Context/authContext'

function Music() {

    const [tracks, setTracks] = useState([{}])

    const { user } = useContext(Context)

    useEffect(() => {
        const getMusicTracks = async () => {
            try {
                const res = await axios.get("https://api.plausemedia.co.ke/api/tracks")
                setTracks(res.data)
            } catch (err) {
                console.log(err);
            }
        }
        getMusicTracks()
    }, [])

    return (
        <div className='Music'>
            <Sidebar />
            <div className="music-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="add-new-track">
                    <Link to="/music/new">
                        <button>Add New Track</button>
                    </Link>
                </div>
                <div className="music-body" tracks={tracks}>
                    {
                        tracks.map((t) => (
                            <Musicbubble track={t} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Music