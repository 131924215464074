import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { MdDashboard, MdOutlineSmartDisplay, MdLogout, MdSettingsSystemDaydream, MdSettings } from 'react-icons/md'
import { BsCreditCard2BackFill, BsBroadcastPin } from 'react-icons/bs'
import { IoStatsChartSharp, IoMusicalNotesSharp } from 'react-icons/io5'
import { TbCalendarStats } from 'react-icons/tb'
import { RiMessage2Fill } from 'react-icons/ri'
import { GiPortal } from 'react-icons/gi'
import { CgProfile } from 'react-icons/cg'
import { FaUsers } from 'react-icons/fa'
import '../Sidebar/Sidebar.scss'
import Logo from '../../Resources/plausemedeiared.png'
import { Context } from '../../Context/authContext';
import { useEffect } from 'react';
import { useState } from 'react';


export default function Sidebar() {

    const { user, dispatch } = useContext(Context)
    const [userProfile, setUserProfile] = useState({})    

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" })
        window.location.replace("/login");
    }

    useEffect(() => {
        if (user) {
            let userDetails = []
            userDetails.push(user.dataValues)
            setUserProfile(userDetails[0])
        }
    }, [user])


    return (
        <div className="Sidebar">
            <div className="site-id">
                <h3>Artist Portal  <GiPortal /></h3>
            </div>
            <div className="heading">
                <img src={Logo} alt="" />
            </div>
            <div className="tags">MAIN</div>
            <div className="tab">

                <NavLink to="/">
                    Dashboard
                    <MdDashboard id="tab-logo" />
                </NavLink>
            </div>
            <div className="tags">LISTS</div>
            <div className="tab">
                {userProfile.role === "Admin" ? <NavLink to="/users">
                    Users
                    <FaUsers id="tab-logo" />
                </NavLink> : null}
            </div>
            <div className="tab">

                <NavLink to="/music">
                    Music
                    <IoMusicalNotesSharp id="tab-logo" />
                </NavLink>
            </div>
            <div className="tab">

                <NavLink to="/distribute">
                    Distribute
                    <MdOutlineSmartDisplay id="tab-logo" />
                </NavLink>
            </div>
            <div className="tags">USEFUL</div>
            <div className="tab">

                <NavLink to="/stats">
                    Stats
                    <IoStatsChartSharp id="tab-logo" />
                </NavLink>
            </div>
            <div className="tab">

                <NavLink to="/messages">
                    Messages
                    <RiMessage2Fill id="tab-logo" />
                </NavLink>
            </div>
            <div className="tags">SERVICE</div>
            <div className="tab">

                <NavLink to="/others" style={{ pointerEvents: 'none' }}>
                    System Health
                    <MdSettingsSystemDaydream id="tab-logo" />
                </NavLink>
            </div>
            <div className="tab">

                <NavLink to="/others" style={{ pointerEvents: 'none' }}>
                    Logs
                    <TbCalendarStats id="tab-logo" />
                </NavLink>
            </div>
            <div className="tab">

                <NavLink to="/settings">
                    Settings
                    <MdSettings id="tab-logo" />
                </NavLink>
            </div>
            <div className="tags">USER</div>
            <div className="tab">

                <NavLink to={`/profile/${userProfile.fullname}`}>
                    Profile
                    <CgProfile id="tab-logo" />
                </NavLink>
            </div>
            <div className="tab">

                <div className='logout' onClick={handleLogout}>
                    Logout
                    <MdLogout id="tab-logo" />
                </div>
            </div>
        </div>
    )
}
