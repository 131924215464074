import '../Musicbubble/Musicbubble.scss'
import plausemedeiared from '../../Resources/plausemedeiared.png'
import { CgMoreO } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { Context } from '../../Context/authContext'
import { useEffect } from 'react'
import { useState } from 'react'

const Musicbubble = ({ track }) => {

    const { user } = useContext(Context)

    const PF = "https://api.plausemedia.co.ke/assets/"

    const [userProfile, setUserProfile] = useState({})
    
    useEffect(() => {
        if (user) {
            let userDetails = []
            userDetails.push(user.dataValues)
            setUserProfile(userDetails[0])
        }
    }, [user])

    return (
        <>
            {userProfile.role === "Admin" | track.username === userProfile.username && (
                <div className='Musicbubble'>

                    <div className="overlay">
                        <Link to={`/music/${track.id}`}><CgMoreO id='more' /></Link>
                    </div>
                    <div className="image-container">
                        {track.photo ? <img src={PF + track.photo} alt="" /> : <img src={plausemedeiared} alt="" />}
                    </div>
                    <div className="bubble-container">
                        <div className="track-title">
                            {track.name}
                        </div>
                        <div className="artist">
                            {track.username}
                        </div>
                        <div className="distribution-stats">
                            Distributed copies: 25 - copies
                        </div>
                        <div className="date-of-release">
                            Date Added: {new Date(track.createdAt).toDateString()}
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}

export default Musicbubble;