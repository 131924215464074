import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import '../Stats/Stats.scss'
import { BsSpotify, BsYoutube, BsApple } from 'react-icons/bs'
import { ImSoundcloud } from 'react-icons/im'
import { SiAudiomack } from 'react-icons/si'

export const Stats = () => {
    return (
        <div className='Stats'>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="stats-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="body-component">
                    <div className="daily-stats">
                        <h2>Daily Stats:</h2>
                        <div className="daily-stats-platforms">
                            <div className="daily-stats-component">
                                <div className="title">Spotify</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 5000</span>
                                        <span>Likes: 3000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsSpotify id='spotify' />
                                    </div>
                                </div>
                            </div>
                            <div className="daily-stats-component">
                                <div className="title">Youtube</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 5000</span>
                                        <span>Likes: 3000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsYoutube id='youtube' />
                                    </div>
                                </div>
                            </div>
                            <div className="daily-stats-component">
                                <div className="title">Apple Music</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 5000</span>
                                        <span>Likes: 3000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsApple id='apple' />
                                    </div>
                                </div>
                            </div>
                            <div className="daily-stats-component">
                                <div className="title">SoundCloud</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 5000</span>
                                        <span>Likes: 3000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <ImSoundcloud id='sound' />
                                    </div>
                                </div>
                            </div>
                            <div className="daily-stats-component">
                                <div className="title">AudioMark</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 5000</span>
                                        <span>Likes: 3000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <SiAudiomack id='audio' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="monthly-stats">
                        <h2>Monthly Stats:</h2>
                        <div className="monthly-stats-platforms">
                            <div className="monthly-stats-component">
                                <div className="title">Spotify</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 50000</span>
                                        <span>Likes: 30000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsSpotify id='spotify' />
                                    </div>
                                </div>
                            </div>
                            <div className="monthly-stats-component">
                                <div className="title">Youtube</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 50000</span>
                                        <span>Likes: 30000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsYoutube id='youtube' />
                                    </div>
                                </div>
                            </div>
                            <div className="monthly-stats-component">
                                <div className="title">Apple Music</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 50000</span>
                                        <span>Likes: 30000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <BsApple id='apple' />
                                    </div>
                                </div>
                            </div>
                            <div className="monthly-stats-component">
                                <div className="title">SoundCloud</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 50000</span>
                                        <span>Likes: 30000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <ImSoundcloud id='sound' />
                                    </div>
                                </div>
                            </div>
                            <div className="monthly-stats-component">
                                <div className="title">AudioMark</div>
                                <div className="streams">
                                    <div className="left">
                                        <span>Streams: 50000</span>
                                        <span>Likes: 30000</span>
                                        <span className='link'>View more</span>
                                    </div>
                                    <div className="right">
                                        <SiAudiomack id='audio' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
