import React from 'react'
import '../list/List.scss'
import Sidebar from '../../Components/Sidebar/Sidebar'
import Navbar from '../../Components/Navbar/Navbar'
import UserComponent from '../../Components/UserComponent/UserComponent'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const List = () => {

  const [users, setUsers] = useState([{}])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get("https://api.plausemedia.co.ke/api/users")
        setUsers(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    getUsers()
  }, [])

  return (
    <div className='List'>
        <Sidebar />

      <div className="list-component">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="add-new-user">
          <Link to='/users/register-user'>Add New</Link>
        </div>
        <div className='Datatable'>
          <div className="top">
            <div className="table-head">Username</div>
            <div className="table-head" id='email'>Email Address</div>
            <div className="table-head">Gender</div>
            <div className="table-head">Age</div>
            <div className="table-head">Status</div>
          </div>
          <div className="table-body" users={users}>
            {
              users.map((u) => (
                <UserComponent key={users.id} user={u} />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default List;