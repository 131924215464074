import React, { useContext } from 'react'
import { useRef } from 'react'
import '../login/Login.scss'
import axios from 'axios'
import { Context } from '../../Context/authContext'

export default function Login() {

  const userRef = useRef()
  const passwordRef = useRef()

  const { dispatch, isFetching } = useContext(Context)

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("https://api.plausemedia.co.ke/api/auth/login", {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  }

  return (
    <div className='Login'>
      <form className="login-form">
        <div className="login-title">Login</div>
        <div className="form-item">
          <input type="text" placeholder='Enter your Username' ref={userRef} />
        </div>
        <div className="form-item">
          <input type="password" placeholder='Enter your Password' ref={passwordRef} />
        </div>
        <div className="login">
          <button disabled={isFetching} onClick={handleSubmit}>Login</button>
        </div>
      </form>
    </div>
  )
}