import React from 'react'
import '../Table/Table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const List = () => {
    const rows = [
        {
            id: 1234,
            Songname: "Indie Film",
            img: "",
            platform: "Spotify",
            streams: "50,000",
            date: "1 March",
        },
        {
            id: 2345,
            Songname: "Bruklynn Boyz Record",
            img: "",
            platform: "Youtube",
            streams: "25,000",
            date: "3 October",
        },
        {
            id: 1456,
            Songname: "Poem Record",
            img: "",
            platform: "Apple Music",
            streams: "20,000",
            date: "1 June",
        },
        {
            id: 3456,
            Songname: "Sailors E-P",
            img: "",
            platform: "Spotify",
            streams: "18,900",
            date: "25 August",
        },
        {
            id: 3567,
            Songname: "Ishmael Akro Mixtape",
            img: "",
            platform: "SoundCloud",
            streams: "15,450",
            date: "14 April",
        },
        {
            id: 1256,
            Songname: "Under the Sun Indie Production",
            img: "",
            platform: "AudioMark",
            streams: "12,380",
            date: "15 June",
        }

    ]

    return (
        <TableContainer component={Paper} className="Table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='head'>
                    <TableRow>
                        <TableCell className="tableHead">Song Name</TableCell>
                        <TableCell className="tableHead">Platform</TableCell>
                        <TableCell className="tableHead">Streams/Likes</TableCell>
                        <TableCell className="tableHead">Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id} className="table-row">
                            <TableCell className="tableCell">
                                    <div className="cellWrapper">
                                        <img src={row.img} alt="" className="image"/>
                                        {row.Songname}
                                    </div>
                                </TableCell>
                            <TableCell className="tableCell">{row.platform}</TableCell>
                            <TableCell className="tableCell">{row.streams}</TableCell>
                            <TableCell className="tableCell">{row.date}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default List