import React from 'react'
import '../home/Home.scss'
import Sidebar from '../../Components/Sidebar/Sidebar'
import Widget from '../../Components/Widget/Widget'
import Featured from '../../Components/featured/Featured'
import Chart from '../../Components/chart/Chart'
import Navbar from '../../Components/Navbar/Navbar'
import Table from '../../Components/Table/Table'


const Home = ({title}) => {
  return (
    <div className='Home'>
        <Sidebar />
      <div className="body-component">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="widget-title">
          <h2>Daily Summary:</h2>
        </div>
        <div className="widgets">
          <Widget type="Spotify" />
          <Widget type="YouTube" />
          <Widget type="Apple-Music" />
          <Widget type="Soundcloud" />
        </div>
        <div className="charts-container">
          <Featured />
          <Chart title="Stats for the last 6 months" />
        </div>
        <div className="listContainer">
            <div className="listTitle">Song Performance Rankings</div>
            <Table/>
        </div>
      </div>
    </div>
  )
}

export default Home;