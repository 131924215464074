import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from '../../../Components/Navbar/Navbar'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import '../SingleDistribution/SingleDistribution.scss'

export default function SingleDistribution() {

    const location = useLocation()
    const path = location.pathname.split('/')[2]
    const [track, setTrack ] = useState({})

    const PF = "https://api.plausemedia.co.ke/assets/"

    useEffect(() => {
        const getTrack = async() => {
            try {
                const res = await axios.get("https://api.plausemedia.co.ke/api/tracks/" + path)
                setTrack(res.data)
            } catch (err) {
                console.log(err)
            }
        }
        getTrack()
    }, [path])

    return (
        <div className='SingleDistribution'>
                <Sidebar />
            <div className="SingleDistribution-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="body-component">
                    <div className="track-title">{track.name}:</div>
                    <div className='disto-img'><img src={PF + track.photo} alt="" /></div>
                    <div className="distribution-details">
                        <div className="distro-platform">
                            <h3>Platform</h3>
                            <div className="platform-item">Spotify:</div>
                            <div className="platform-item">YouTube:</div>
                            <div className="platform-item">SoundCloud:</div>
                            <div className="platform-item">AudioMark:</div>
                            <div className="platform-item">Apple Music:</div>
                        </div>
                        <div className="distro-link">
                            <h3>Link</h3>
                            <div className="platform-item">{track.spotifyLink}</div>
                            <div className="platform-item">{track.youTubeLink}</div>
                            <div className="platform-item">{track.soundCloudLink}</div>
                            <div className="platform-item">AudioMark</div>
                            <div className="platform-item">Apple Music</div>
                        </div>
                        <div className="distro-date">
                            <h3>Date Distributed</h3>
                            <div className="platform-item">{new Date(track.createdAt).toDateString()}</div>
                            <div className="platform-item">{new Date(track.createdAt).toDateString()}</div>
                            <div className="platform-item">{new Date(track.createdAt).toDateString()}</div>
                            <div className="platform-item">{new Date(track.createdAt).toDateString()}</div>
                            <div className="platform-item">{new Date(track.createdAt).toDateString()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
