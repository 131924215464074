import '../notificationBubble/NotificationBubble.scss'
import image from '../../Resources/plausemedeiared.png'

export default function NotificationBubble(){
    return(
        <div className="notificationBubble">
            <div className="logo">
                <img src={image} alt="" />
            </div>
            <div className="notification-preview">
                <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad molestiae deserunt, amet temporibus voluptatum repudiandae ratione porro eaque natus optio dolore quos veritatis. Accusantium animi tempore hic maxime? Quis, commodi?
                </p>
            </div>
            <div className="time">
                <p>13:07</p>
            </div>
        </div>
    )
}