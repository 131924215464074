import Navbar from '../../../Components/Navbar/Navbar'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import '../AddNew/Addnew.scss'
import { useState } from 'react'
import axios from 'axios'

export default function AddNew() {
    const [pageOne, setPageOne] = useState(true)
    const [pageTwo, setPageTwo] = useState(false)
    const [pageThree, setPageThree] = useState(false)
    const [previewMode, setPreviewMode] = useState(false)
    const [email, setEmail] = useState("")
    const [telephone, setTelephone] = useState("")
    const [artistName, setArtistName] = useState("")
    const [featureList, setFeatureList] = useState([])
    const [album, setAlbum] = useState("")
    const [songName, setSongName] = useState("")
    const [songLanguage, setSongLanguage] = useState("")
    const [imageFile, setImageFile] = useState("")
    const [audioFile, setAudioFile] = useState("")
    const [genre, setGenre] = useState("")
    const [subGenre, setSubGenre] = useState("")
    const [country, setCountry] = useState("")
    const [recordingYear, setRecordingYear] = useState("")
    const [youTubeLink, setYouTubeLink] = useState("")
    const [explicit, setExplicit] = useState("")
    const [rights, setRights] = useState("")
    const [featureInputs, setFeatureInputs] = useState([]);


    const addNewInput = () => {
        setFeatureInputs([...featureInputs, '']);
    };

    const handleInputChange = (index, value) => {
        const newInputs = [...featureInputs];
        newInputs[index] = value;
        setFeatureInputs(newInputs);
    };

    const removeInput = (index) => {
        const newInputs = [...featureInputs];
        newInputs.splice(index, 1);
        setFeatureInputs(newInputs);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const newTrack = {
            email: email,
            telephone: telephone,
            artistName: artistName,
            featureList: featureList,
            album: album,
            songName: songName,
            songLanguage: songLanguage,
            genre: genre,
            subGenre: subGenre,
            country: country,
            recordingYear: recordingYear,
            youTubeLink: youTubeLink,
            explicit: explicit,
            rights: rights,
        }
        if (imageFile) {
            const formData = new FormData();
            const filename = Date.now() + imageFile.name;
            formData.append('name', filename)
            formData.append('file', imageFile)
            newTrack.photo = filename;
            try {
                await axios.post('https://api.plausemedia.co.ke/uploads', formData)
            } catch (err) {
                console.log(err);
            }
        }
        if (audioFile) {
            const formData = new FormData();
            const filename = Date.now() + audioFile.name;
            formData.append('name', filename)
            formData.append('file', audioFile)
            newTrack.audio = filename;
            try {
                await axios.post('https://api.plausemedia.co.ke/uploads/tracks', formData)
            } catch (err) {
                console.log(err);
            }
        }
        try {
            const res = await axios.post('https://api.plausemedia.co.ke/api/tracks', newTrack)
            window.location.replace('/music/' + res.data.id)
        } catch (err) {
            console.log(err)
        }
    }
    
    return (
        <div className='Addnew'>
            <Sidebar />
            <div className="new-music-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <form className="new-music-form">
                    {pageOne && <div className="page-one">
                        <div className="information-container">
                            <h1>Artist Information</h1>
                            <input type="text" placeholder="Enter your email address" required onChange={(e) => setEmail(e.target.value)} />
                            <input type="text" placeholder="Enter your phone number" required onChange={(e) => setTelephone(e.target.value)} />
                            <input type="text" placeholder="Enter the main artist's name" required onChange={(e) => setArtistName(e.target.value)} />
                            <div className="feature">
                                <div className="feature-inputs">
                                    <div>
                                        <input type="text" className="artist-name" onChange={(e) => setFeatureList(e.target.value)} placeholder="Enter the name of featured artist" />
                                        <span className="add" onClick={addNewInput}>+</span>
                                    </div>
                                    {featureInputs && featureInputs.map((inputValue, index) => (
                                        <div>
                                            <input
                                                key={index}
                                                type="text"
                                                className="artist-name"
                                                placeholder="Enter the name of featured artist"
                                                value={inputValue}
                                                onChange={(event) => {
                                                    handleInputChange(index, event.target.value)
                                                    setFeatureList(event.target.value)
                                                }}
                                            />
                                            <span onClick={() => removeInput(index)} className="remove">-</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {!previewMode && <div className="next">
                                <span
                                    id="next-form"
                                    onClick={() => {
                                        setPageOne(false)
                                        setPageTwo(true)
                                    }}
                                > Next
                                </span>
                            </div>}
                        </div>
                    </div>}
                    {pageTwo && <div className="page-two">
                        <h1>Music Infromation</h1>
                        <div className="information-container">
                            <input type="text" placeholder="Enter album/EP name" required onChange={(e) => setAlbum(e.target.value)} />
                            <input type="text" placeholder="Enter song name" required onChange={(e) => setSongName(e.target.value)} />
                            <input type="text" placeholder="Enter song language" required onChange={(e) => setSongLanguage(e.target.value)} />
                            <div className="uploads">
                                <label>
                                    Select song art (up to 10mbs)
                                    <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />
                                </label>
                                <label>
                                    Select song audio file
                                    <input type="file" onChange={(e) => setAudioFile(e.target.files[0])} />
                                </label>
                            </div>
                            <select type="text" placeholder="Enter song Genre" required onChange={(e) => setGenre(e.target.value)}>
                                <option>Rock</option>
                                <option>Pop</option>
                                <option>Reggae</option>
                                <option>Hip-Hop</option>
                                <option>R & B</option>
                                <option>Blues</option>
                            </select>
                            <input type="text" placeholder="Enter song Sub-genre" required onChange={(e) => setSubGenre(e.target.value)} />
                            {!previewMode && <div className="next-2">
                                <span
                                    id="next-form"
                                    onClick={() => {
                                        setPageOne(false)
                                        setPageTwo(false)
                                        setPageThree(true)
                                    }}
                                > Next
                                </span>
                            </div>}
                        </div>
                    </div>}
                    {pageThree && <div className="page-three">
                        <div className="information-container">
                            <h1>Music Attributes</h1>
                            <input type="text" placeholder="Enter your Country" required onChange={(e) => setCountry(e.target.value)} />
                            <input type="text" placeholder="Enter recording year" required onChange={(e) => setRecordingYear(e.target.value)} />
                            <input type="text" placeholder="Enter youTube link" required onChange={(e) => setYouTubeLink(e.target.value)} />
                            <select type="text" required onChange={(e) => setExplicit(e.target.value)}>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                            <input type="text" placeholder="Enter rights management" required onChange={(e) => setRights(e.target.value)} />
                            {!previewMode ? <div className="next-3">
                                <span
                                    id="next-form"
                                    onClick={() => {
                                        setPageOne(true)
                                        setPageTwo(true)
                                        setPageThree(true)
                                        setPreviewMode(true)
                                    }}
                                > Preview
                                </span>
                            </div> :
                                <div className="submit">
                                    <span id="cancel"
                                        onClick={() => {
                                            setPageOne(false)
                                            setPageTwo(false)
                                            setPageThree(true)
                                            setPreviewMode(false)
                                        }}
                                    >
                                        Cancel</span>
                                    <button type='submit' id="submit" onClick={handleSubmit}>Submit</button>
                                </div>
                            }
                        </div>
                    </div>}
                </form>
                {!previewMode && <div className="scroll-bar">
                    <span id="one"
                        onClick={() => {
                            setPageOne(true)
                            setPageTwo(false)
                            setPageThree(false)
                        }}
                        style={{ backgroundColor: pageOne ? "#ff000045" : "" }}
                    >1
                    </span>
                    <span id="two"
                        onClick={() => {
                            setPageOne(false)
                            setPageTwo(true)
                            setPageThree(false)
                        }}
                        style={{ backgroundColor: pageTwo ? "#ff000045" : "" }}
                    >2
                    </span>
                    <span id="three"
                        onClick={() => {
                            setPageOne(false)
                            setPageTwo(false)
                            setPageThree(true)
                        }}
                        style={{ backgroundColor: pageThree ? "#ff000045" : "" }}
                    >3
                    </span>
                </div>}
            </div>
        </div>
    )
}