import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import DistributeBubble from '../../Components/DistributeBubble/DistributeBubble'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import '../Distribute/Distribute.scss'

function Distribute() {

    const [tracks, setTracks] = useState([{}])

    useEffect(() => {
        const getTracks = async (req, res) => {
            try {
                const res = await axios.get('https://api.plausemedia.co.ke/api/tracks')
                setTracks(res.data)
            } catch (err) {
                console.log(err);
            }
        }
        getTracks();
    }, [])


    return (
        <div className='Distribute'>
                <Sidebar />
            <div className="distribution-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="body-component">
                    <div className='Datatable'>
                        <div className="top">
                            <div className="table-head" id='title'>Song Title</div>
                            <div className="table-head">Artist Name</div>
                            <div className="table-head">Album Name</div>
                            <div className="table-head">Duration</div>
                        </div>
                        <div className="table-body" tracks={tracks}>
                            {
                                tracks.map((t) => (
                                    <DistributeBubble track={t} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Distribute
