import React from 'react'
import '../featured/Featured.scss'
import { MdOutlineMoreVert, MdOutlineArrowUpward, MdOutlineArrowDownward } from 'react-icons/md'
import { CircularProgressbar } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css"


const Featured = () => {
  return (
    <div className='Featured'>
      <div className="top">
        <h1>Total Daily Streams</h1>
        <MdOutlineMoreVert />
      </div>
      <div className="bottom">
          <div className="heading">Summary</div>
          <div className="featured-item">
            <span className='name'>Spotify:</span>
            <span className='amount'>5000</span>
          </div>
          <div className="featured-item">
            <span className='name'>YouTube:</span>
            <span className='amount'>5000</span>
          </div>
          <div className="featured-item">
            <span className='name'>Apple:</span>
            <span className='amount'>5000</span>
          </div>
          <div className="featured-item">
            <span className='name'>SoundCloud:</span>
            <span className='amount'>5000</span>
          </div>
          <div className="featured-item">
            <span className='name'>AudioMark:</span>
            <span className='amount'>5000</span>
          </div>
          <div className="featured-item">
            <span className='name'>Deezer:</span>
            <span className='amount'>5000</span>
          </div>
      </div>
    </div>
  )
}

export default Featured