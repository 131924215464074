import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import '../RegisterUsers/RegisterUsers.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const RegisterUsers = () => {
    const [fullname, setFullName] = useState("");
    const [username, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("")

    const Navigate = useNavigate()

    const [error, setError] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newUser = {
            fullname: fullname,
            username: username,
            email: email,
            password: password,
            role: role,
        }        

        try {
            await axios.post('https://api.plausemedia.co.ke/api/auth/register', newUser)
            Navigate('/users')
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='RegisterUsers'>            
                <Sidebar />            
            <div className="new-user-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="registration-body">
                    <form onSubmit={handleSubmit}>
                        <div className="title">
                            <h1>Register New User</h1>
                        </div>
                        <div className="form-item">
                            <input type="text" onChange={e => setFullName(e.target.value)} placeholder="Enter User Fullname" />
                        </div>
                        <div className="form-item">
                            <input type="text" onChange={e => setUserName(e.target.value)} placeholder="Enter User Username" />
                        </div>
                        <div className="form-item">
                            <input type="text" onChange={e => setEmail(e.target.value)} placeholder="Enter User Email" />
                        </div>
                        <div className="form-item">
                            <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Enter User Password" />
                        </div>
                        <div className="form-item">
                            <select value={role} id="" onChange={e => setRole(e.target.value)}>
                                <option>Admin</option>
                                <option>User</option>
                            </select>
                        </div>
                        <button type='submit'>Register</button>
                        {error && <span style={{ color: "red", marginTop: "2rem" }}>Something went Wrong</span>}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterUsers