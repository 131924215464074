import React from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'
import Navbar from '../../Components/Navbar/Navbar'
import '../single/Single.scss'
import image from '../../Resources/index.jpeg'
import Chart from '../../Components/chart/Chart'
import List from '../../Components/Table/Table';
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

const Single = () => {

  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const [userinfo, setUserInfo] = useState({})

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await axios.get('https://api.plausemedia.co.ke/api/users/' + path)
        setUserInfo(res.data)
      } catch (err) {
        console.log(err);
      }
    }
    getUserInfo()
  }, [path])

  return (
    <div className='Single'>
        <Sidebar />
      <div className="single-component">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="title">
          <h1>Session Info</h1>
        </div>
        <div className="top">
          <div className="left">
            <div className="session-info">
              <div className="user-status">
                <div className="session-item">
                  <h3>Name:</h3>
                  <p>{userinfo.fullname}</p>
                </div>
                <div className="session-item">
                  <h3>Status:</h3>
                  <div className='status'>Online</div>
                </div>
              </div>
              <div className="buttons">
                <div className="session-item">
                  <div className='logout'>Logout</div>
                </div>
                <div className="session-item">
                  <div className='notify'>Send Notification</div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="session-item">
              <h3>Email:</h3>
              <p>{userinfo.email}</p>
            </div>
            <div className="session-item">
              <h3>Phone:</h3>
              <p>{userinfo.telephone}</p>
            </div>
            <div className="session-item">
              <h3>Spotify:</h3>
              <p>KrispaIvy</p>
            </div>
            <div className="session-item">
              <h3>YouTube:</h3>
              <p>Krispa Ivy</p>
            </div>
          </div>
        </div>
        <div className="bottom">
          <List />
        </div>
      </div>
    </div>
  )
}

export default Single