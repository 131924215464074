import React from 'react'
import '../DistributeBubble/DistributeBubble.scss'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { Context } from '../../Context/authContext'
import { useState } from 'react'
import { useEffect } from 'react'

export default function DistributeBubble({ track }) {

  const { user } = useContext(Context)

  const [userProfile, setUserProfile] = useState({})

  useEffect(() => {
    if (user) {
      let userDetails = []
      userDetails.push(user.dataValues)
      setUserProfile(userDetails[0])
    }
  }, [user])

  return (
    <Link to={`/distribute/${track.id}`}>
      <>
        {userProfile.role === "Admin" | track.username === userProfile.username && (
          <div className="DistributeBubble-component">
            <div className="component-body" id='title'>{track.name}</div>
            <div className="component-body">{track.username}</div>
            <div className="component-body">{track.album}</div>
            <div className="component-body">{track.duration}</div>
          </div>
        )}
      </>
    </Link>
  )
}
