import styled from "styled-components";
import './Navbar.scss'
import React, { useContext, useEffect, useState } from 'react'
import image from '../../Resources/index.jpeg'
import { MdOutlineLanguage, MdOutlineFullscreen, MdOutlineNotificationsActive, MdOutlineChatBubble, MdOutlineList, MdOutlineSearch } from 'react-icons/md'
import { Context } from "../../Context/authContext";
import { HiUserCircle } from 'react-icons/hi'

const Navbar = () => {

    const { user } = useContext(Context)
    const [userProfile, setUserProfile] = useState({})    

    useEffect(() => {
        if (user) {
            let userDetails = []
            userDetails.push(user.dataValues)
            setUserProfile(userDetails[0])
        }
    }, [user])


    return (
        <div className="container">
            <div>

            </div>
            <div className="site-search">
                <input type="text" placeholder="Search..." />
                <MdOutlineSearch id="search" />
            </div>
            <div className="site-items">
                <div className="items">
                    <div className="item">
                        <MdOutlineLanguage />
                        <h3>English</h3>
                    </div>
                    <div className="item">
                        <MdOutlineFullscreen />
                    </div>
                    <div className="item">
                        <MdOutlineNotificationsActive />
                        <div className="counter">1</div>
                    </div>
                    <div className="item">
                        <MdOutlineChatBubble />
                        <div className="counter">2</div>
                    </div>
                    <div className="item">
                        <MdOutlineList />
                    </div>

                    <div className="item">
                        {userProfile.profilePic == null ? <HiUserCircle id="icon" /> : <img src={userProfile.profilePic} alt="" />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;