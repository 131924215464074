import '../Notifications/Notifications.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import NotificationBubble from '../../Components/notificationBubble/NotificationBubble'
import { MdDeleteForever } from 'react-icons/md'
import image from '../../Resources/plausemedeiared.png'
import { useContext, useEffect, useRef } from 'react'
import { io } from 'socket.io-client';
import { Context } from '../../Context/authContext'

export default function Notifications() {

    const { user } = useContext(Context)
    const socket = useRef()

    useEffect(() => {
        socket.current = io('http://localhost:4000')
    }, [])

    useEffect(() => {
        socket.current.emit("addUser", user?.id)
        socket.current.on("getUsers", users => {
            console.log(users)
        })

    }, [user])


    return (
        <div className="Notifications">            
                <Sidebar />            
            <div className="notifications-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="notification-body">
                    <div className="left">
                        <div className="heading">Notifications</div>
                        <div className="notification-box">
                            <NotificationBubble />
                            <NotificationBubble />
                            <NotificationBubble />
                            <NotificationBubble />
                            <NotificationBubble />
                        </div>
                    </div>
                    <div className="right">
                        <div className="heading">
                            <div className="user-details">
                                <div className="image">
                                    <img src={image} alt="" />
                                </div>
                                <div className="username">Username</div>
                            </div>
                            <div className="actions">
                                <MdDeleteForever />
                            </div>
                        </div>
                        <div className="notification-chat">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}