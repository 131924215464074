import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { BsToggleOn, BsToggleOff, BsInstagram } from 'react-icons/bs'
import { MdOutlineLanguage, MdOutlineEmail, MdOutlinePhone } from 'react-icons/md'
import { GoUnmute, GoMute } from 'react-icons/go'
import '../Settings/Settings.scss'
import { RiVolumeVibrateFill } from 'react-icons/ri'

export const Settings = () => {
    return (
        <div className='Settings'>
                <Sidebar />
            <div className="Settings-component">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="body-component">
                    <div className="heading">Display</div>
                    <div className="setting-section disp">
                        <span>Dark/Light Mode</span>
                        <span><BsToggleOn id='toggle' /></span>
                    </div>
                    <div className="heading">Notifications</div>
                    <div className="setting-section sound">
                        <span>
                            <p><GoUnmute/> Loud</p>
                            <input type="checkbox" />
                        </span>
                        <span>
                            <p><RiVolumeVibrateFill/> Meeting</p>
                            <input type="checkbox" />
                        </span>
                        <span>
                            <p><GoMute/> Silent</p>
                            <input type="checkbox" />
                        </span>
                    </div>
                    <div className="heading">Language</div>
                    <div className="setting-section language">
                        <p>Select your prefered Language</p>
                        <div className="options">
                            <MdOutlineLanguage id='lang' />
                            <select name="" id="">
                                <option value="">English</option>
                                <option value="">French</option>
                            </select>
                        </div>
                    </div>
                    <div className="heading">Utilities</div>
                    <div className="setting-section utility">
                        <span>Terms of Service</span>
                        <span>Raise Complaint</span>
                    </div>
                    <div className="heading">Contact Us</div>
                    <div className="setting-section contact">
                        <form action="">
                            <input type="text" placeholder='Enter your Fullname' />
                            <input type="text" placeholder='Enter your Email Address' />
                            <textarea name="" placeholder='Enter Message'></textarea>
                        </form>
                        <div className="contact-details">
                            <span>
                                <p>Email: plausemediake.gmail.com</p>
                                <MdOutlineEmail id='social' />
                            </span>
                            <span>
                                <p>Tel: +254798999992</p>
                                <MdOutlinePhone id='social' />
                            </span>
                            <span>
                                <p>Instagram: PlauseMediaKe</p>
                                <BsInstagram id='social' />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
