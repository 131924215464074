import React from 'react'
import '../chart/Chart.scss'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'January', Total: 1200 },
  { name: 'February', Total: 2500 },
  { name: 'March', Total: 600 },
  { name: 'April', Total: 1800 },
  { name: 'May', Total: 2450 },
  { name: 'June', Total: 1000 },
];

const Chart = ({aspect, title}) => {
  return (
    <div className="Chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="99%" height="90%" aspect={aspect}>
        <AreaChart width={730} height={250} data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3b3b3b" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffffff73" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="3 3" className='chartGrid'/>
          <Tooltip />
          <Area type="monotone" dataKey="Total" stroke="#ffffff6c" fillOpacity={1} fill="url(#total)" />

        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart