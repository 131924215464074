import '../Singlemusic/Singlemusic.scss'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import Navbar from '../../../Components/Navbar/Navbar'
import plausemedeiared from '../../../Resources/plausemedeiared.png'
import { ImYoutube2, ImSpotify, ImSoundcloud } from 'react-icons/im'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

export default function Singlemusic(){

    const location = useLocation()
    const path = location.pathname.split('/')[2]
    const [track, setTrack ] = useState({})
    const PF = "https://api.plausemedia.co.ke/assets/"

    useEffect(() => {
        const getTrack = async() => {
            try {
                const res = await axios.get("https://api.plausemedia.co.ke/api/tracks/" + path)
                setTrack(res.data)
            } catch (err) {
                console.log(err)
            }
        }
        getTrack()
    }, [path])
    return(
        <div className='Singlemusic'>
                <Sidebar/>
            <div className="single-music-component">
                <div className="navbar">
                    <Navbar/>
                </div>
                <div className="track-info">
                    <div className="left">
                        <div className="image-holder">
                            <img src={PF + track.photo} alt="" />
                        </div>
                        <div className="track-details">
                            <h2>{track.name}</h2>
                            <p>Artist: {track.username}</p>
                            <p>Album: {track.album}</p>
                            <p>Duration: {track.duration}</p>
                            <p>Release Date: {new Date(track.createdAt).toDateString()}</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="track-bio">
                            <h3>About Track</h3>
                            <p>
                                {track.desc}
                            </p>
                        </div>
                        <div className="distribution-info">
                            <ImYoutube2 id='dist-logo'/>
                            <ImSpotify id='dist-logo'/>
                            <ImSoundcloud id='dist-logo'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}