import React from 'react'
import {BsSpotify, BsYoutube, BsApple} from 'react-icons/bs'
import { ImSoundcloud } from 'react-icons/im'
import '../Widget/Widget.scss'

const Widget = ({ type }) => {
    let data;

    const amount = 100;
    const diff = 20;

    switch (type) {
        case "Spotify":
            data = {
                title: "SPOTIFY",
                streams: 42,
                likes: 20,
                link: "See all stats",
                icon: <BsSpotify className='icon' style={{ color: "green", backgroundColor: "#0dff003c", borderRadius: "5px", padding: "0.5rem" }} />,
            };
            break;

        case "YouTube":
            data = {
                title: "YOUTUBE",
                streams: 42,
                likes: 20,
                link: "See all stats",
                icon: <BsYoutube className='icon' style={{ color: "red", backgroundColor: "#ff44002d", borderRadius: "5px", padding: "0.5rem" }} />,
            };
            break;

        case "Apple-Music":
            data = {
                title: "APPLE MUSIC",
                streams: 42,
                likes: 20,
                link: "See all stats",
                icon: <BsApple className='icon' style={{ color: "white", backgroundColor: "#646464", borderRadius: "5px", padding: "0.5rem" }} />,
            };
            break;

        case "Soundcloud":
            data = {
                title: "SOUNDCLOUD",
                streams: 42,
                likes: 20,
                link: "See all stats",
                icon: <ImSoundcloud className='icon' style={{ color: "orange", backgroundColor: "#ff660049", borderRadius: "5px", padding: "0.5rem" }} />,
            };
            break;
        default:
            break;
    }

    return (
        <div className='Widget'>
            <div className="left">
                <span className="title">{data.title}</span>
                <div className="stats">
                    <div className="left-side">
                        <span className="streams">Streams: {data.streams}</span>
                        <span className="likes">Likes: {data.likes}</span>
                    </div>
                    <div className="right-side">
                        <span id="icon">{data.icon}</span>
                    </div>
                </div>
            </div>
            <div className="right">
                <span className="link">{data.link}</span>
            </div>
        </div>
    )
}

export default Widget