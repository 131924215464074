import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import '../Profile/Profile.scss'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import { Context } from '../../Context/authContext'
import { HiUserCircle } from 'react-icons/hi'

export default function Profile() {
    const { user } = useContext(Context)
    const [userProfile, setUserProfile] = useState({})  
    
    useEffect(() => {
        if (user) {
            let userDetails = []
            userDetails.push(user.dataValues)
            setUserProfile(userDetails[0])
        }
    }, [user])

    return (
        <div className="Profile">
            <Sidebar />
            <div className="Profile-container">
                <div className="navbar">
                    <Navbar />
                </div>
                <div className="user-profile-info">
                    <div className="left">
                        <div className="title">Artist Info</div>
                        <div className="image-holder">
                        {userProfile.profilePic == null ? <HiUserCircle id="icon" /> : <img src={userProfile.profilePic} alt="" />}
                        </div>
                        <div className="user-section-one">
                            <div className="user-item">
                                <div className="input">{userProfile.username}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.fullname}</div>
                            </div>
                            <div className="user-item">
                                <div className="text">{userProfile.bio ? userProfile.bio : <p>Bio</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="actions">
                            <div className="delete-account">Delete</div>
                            <Link to="/profile/editprofile">Update</Link>
                        </div>
                        <div className="items">
                            <div className="user-item">
                                <div className="input">{userProfile.address ? userProfile.address : <p>Your Address goes here</p>}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.telephone ? userProfile.telephone : <p>Your Phone number goes here</p>}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.country ? userProfile.country : <p>Your Country goes here</p>}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.spotify ? userProfile.spotify : <p>Your Spotify goes here</p>}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.soundCloud ? userProfile.soundCloud : <p>Your Sound Cloud goes here</p>}</div>
                            </div>
                            <div className="user-item">
                                <div className="input">{userProfile.youTube ? userProfile.youTube : <p>Your YouTube goes here</p>}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}